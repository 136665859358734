import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'browse', loadChildren: './browse/browse.module#BrowsePageModule' },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'home',
  //   loadChildren: './home/home.module#HomePageModule'
  // },
  // {
  //   path: 'list',
  //   loadChildren: './list/list.module#ListPageModule'
  // },
  // { path: 'post-view', loadChildren: './post-view/post-view.module#PostViewPageModule' },
  { path: 'page-view', loadChildren: './page-view/page-view.module#PageViewPageModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule' },
  // { path: 'category-list', loadChildren: './category-list/category-list.module#CategoryListPageModule' },
  // { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' }
//   { 
//     path: 'tabs', 
//     component: TabsPage,
//     children: [
//         {
//             path: 'home',
//             // outlet: 'one',
//             loadChildren: './home/home.module#HomePageModule'
//         },
//         // {
//         //     path: 'two',
//         //     outlet: 'home',
//         //     loadChildren: './home/home.module#HomePageModule'
//         // },
//         // {
//         //     path: 'three',
//         //     outlet: 'three',
//         //     loadChildren: '../three/three.module#ThreeModule'
//         // }
//     ]
// },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
