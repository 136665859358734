import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { WordpressService } from './services/wordpress.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

  realMenu =[];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private wpService: WordpressService,

  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString('#ffffff');
      this.splashScreen.hide();
      // this.getMenu();
    });
  }

  // getMenu() {
  //   this.wpService.getMenu()
  //     .subscribe((menuItems: any[]) => {
  //       console.log(menuItems);
  //       this.realMenu = [];
  //       //transform the object key to match endpoint page=pages
  //       menuItems.forEach(el => {
  //         switch (el.object) {
  //           case 'page':
  //             el.object = 'page-view'
  //             break;
  //           case 'category' :
  //             el.object = 'category-list'
  //             break;
  //         }
  //       })
  //       menuItems.forEach(element => {
  //         if (element.menu_item_parent === '0') {
  //           element['children'] = menuItems.filter(el => {
  //             return +el.menu_item_parent === element.ID
  //           })
  //           // Two deep
  //           element['children'].forEach(child2 => {
  //             child2['children'] = menuItems.filter(menu => {
  //               return +menu.menu_item_parent === child2.ID
  //             });
  //           })
  //           this.realMenu.push(element);
  //         }
  //       });
  //       console.log(this.realMenu);
  //     })
  // }
}

