import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  baseUrl = 'https://excellentinsights.co.za/wp-json';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private _media = [];

  public latestPostsObs = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient,
    private storage: Storage) {
    this.storage.remove('posts');

    // this.getMenu();
  }

  // getMedia() {
  //   const path = '/wp/v2/media/?per_page=100';
  //   const url = `${this.baseUrl}${path}`;
  //   this.http.get(url, this.httpOptions)
  //     .subscribe((media: any[]) => {
  //       console.log(media);
  //       this._media = media;
  //     })
  // }

  // getMediaUrl(id: number) {
  //   return new Promise(resolve => {
  //     const path = '/wp/v2/media/' + id;
  //     const url = `${this.baseUrl}${path}`;
  //     this.http.get(url, this.httpOptions)
  //       .subscribe(media => {
  //         resolve(media)
  //       })
  //   })

  // }

  // getPosts(): Observable<any> {
  //   const path = '/wp/v2/posts?_embed';
  //   const url = `${this.baseUrl}${path}`;
  //   return this.http.get(url, this.httpOptions)
  //     .pipe(
  //       // map(el => {return el}),
  //       catchError(this.handleError));
  // }

  // Handle incoming data
  private updateLocalStorage(postsIn: any[]) {
    return new Promise(resolve => {
      this._allPosts()
        .then((allPosts) => {
          let madeChange = false;

          postsIn.forEach(postIn => {
            const index = allPosts.findIndex(el => {
              return el.id === postIn.id
            })

            if (index > -1) {
              if (allPosts[index].modified_gmt !== postIn.modified_gmt) {
                allPosts[index] = postIn;
                madeChange = true;
                console.log('update existing entry');
              }
            } else { //new entry
              madeChange = true;
              allPosts.push(postIn);
            }
          })

          if (madeChange) {
            //console.log(msg)
            this.storage.set('posts', JSON.stringify(allPosts))
              .then(msg => {
                this.latestPostsObs.next(allPosts);
                resolve('success');
              })
              .catch(msg => { console.log(msg) });
          } else {
            resolve('success');
          }
        });
    })
      .catch(obj => {
        console.log("error in getting")
      });
  }

  private _allPosts(): Promise<any[]> {
    return new Promise(resolve => {
      this.storage.get('posts')
        .then((val) => {
          if (val) {
            resolve(JSON.parse(val));
          } else {
            resolve([]);
          }
        })
    })
  }

  

  private fetchPosts(pageNr = 1, perPage = 10) {
    const path = '/wp/v2/posts?_embed=true&page=' + pageNr.toString() + '&per_page=' + perPage.toString();
    const url = `${this.baseUrl}${path}`;

    this.http.get<any[]>(url, this.httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe(posts => {
        this.updateLocalStorage(posts)
      })
  }

  private fetchPost(postId) {
    return new Promise (resolve => {
      const path = '/wp/v2/posts/' + postId + '?_embed=true';
      const url = `${this.baseUrl}${path}`;
      console.log('get singel');
      this.http.get<any[]>(url, this.httpOptions)
        .pipe(catchError(this.handleError))
        .subscribe(post => {
          resolve(post);
        })
    })
    
  }


  public getLatestPosts(pageNr = 1, perPage = 10) {
    console.log('checking for new', pageNr, perPage);
    this.fetchPosts(pageNr, perPage);
    this._allPosts()
      .then(posts => {
        if (posts.length > 0) {
          this.latestPostsObs.next(posts.slice(0, (pageNr * perPage)));
        } 
      })
  }

  public getPost(postId): Promise<any> {
    return new Promise(resolve => {
      this._allPosts()
        .then(posts => {
          console.log(posts);
          const returnPost = posts.find(el => {
            return el.id === +postId;
          })

          if (returnPost) {
            console.log('had the data');
            resolve(returnPost);
          } else {
            this.fetchPost(postId)
              .then(post => {
                resolve(post);
              });
          }
        })
    })
  }



  getPostsByCategory(categoryId: string): Observable<any> {
    const path = '/wp/v2/posts/?categories=' + categoryId;
    const url = `${this.baseUrl}${path}`;
    return this.http.get(url, this.httpOptions)
      .pipe(
        // map(el => {return el}),
        catchError(this.handleError));
  }



  getPage(id): Observable<any> {
    const path = '/wp/v2/pages/' + id + '?_embed';
    const url = `${this.baseUrl}${path}`;
    return this.http.get(url, this.httpOptions)
      .pipe(
        catchError(this.handleError));
  }

  getCategoryPosts(id): Observable<any> {
    const path = '/wp/v2/categories/' + id;
    const url = `${this.baseUrl}${path}`;
    return this.http.get(url, this.httpOptions)
      .pipe(
        catchError(this.handleError));
  }

  getMenu(): Promise<any[]> {
    return new Promise(resolve => {
      this.storage.get('menu')
        .then(storageVal => {
          if (!storageVal) { //menu not loaded
            const path = '/excellent/menu';
              const url = `${this.baseUrl}${path}`;
              return this.http.get(url, this.httpOptions)
                .pipe(
                  catchError(this.handleError))
                  .subscribe((resp:any[]) => {
                    this.storage.set('menu', JSON.stringify(resp));
                    resolve(resp)
                  })
          } else {
            resolve(JSON.parse(storageVal));
          }
        })
    })
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
